import { jsx as _jsx } from "react/jsx-runtime";
import BoxAtom from '@epam/reactjs-common/atoms/layout/Box';
import CircularProgressAtom from '@epam/reactjs-common/atoms/content/CircularProgress';
import { styled } from '@epam/reactjs-common/styles';
import loaderStyles from './loader.styles';
const Loader = styled((props) => {
    return (_jsx(BoxAtom, Object.assign({ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(128, 128, 128, 0.5)", width: "100vw", height: "100vh", position: "fixed", left: "0", top: "0", zIndex: "1000" }, props, { children: _jsx(CircularProgressAtom, { variant: "indeterminate", size: 50 }) })));
}) `
  ${loaderStyles}
`;
export default Loader;
