import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components';
import BoxStyled from '../../../@atoms/layout/box/index.atom';
import { ButtonAtom, DialogActionsAtom, DialogAtom, DialogContentAtom, TypographyAtom } from "@epam/reactjs-common";
import { useDispatch } from "react-redux";
import { setEnableAutoSaveBrokerOrClientComments } from "../../../store/slices/submissionIdReducer";
import SubmissionReviewIcon from "../../../assets/icon/SubmissionDeclinePopup.svg";
import DOMPurify from 'dompurify';
const TextArea = styled.textarea `
    width: 25em;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    outline:none;
    resize:none;
    maxLength: 5000;
`;
const ApprovalPopUp = ({ open, onClose, onApprove }) => {
    const [comments, setComments] = useState('');
    const dispatch = useDispatch();
    const onTextChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue.length <= 5000) {
            const sanitizedValue = DOMPurify.sanitize(inputValue);
            setComments(sanitizedValue);
            // Enable for Auto Save Comments
            if (sanitizedValue.trim().length > 0) {
                dispatch(setEnableAutoSaveBrokerOrClientComments(true));
            }
        }
    };
    const handleApproveClick = () => {
        onApprove(comments);
        setComments('');
        onClose();
    };
    const handleCancelClick = () => {
        setComments('');
        onClose();
    };
    return (_jsxs(DialogAtom, Object.assign({ open: open, sx: {
            padding: "2em",
            ".MuiDialog-paper": {
                maxWidth: "unset"
            }
        } }, { children: [_jsx(DialogContentAtom, Object.assign({ sx: { pr: 4, pl: 4, pb: 1 } }, { children: _jsxs(BoxStyled, { children: [_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', marginRight: '10px' } }, { children: [_jsx(SubmissionReviewIcon, {}), _jsxs(TypographyAtom, Object.assign({ id: "desc", sx: { mt: '2px', ml: '5px', fontSize: "20px" } }, { children: ["Please provide approval comments for", _jsx("br", {}), "Broker/Client"] }))] })), _jsx(TextArea, { className: "comments", id: 'comments', onChange: onTextChange, value: comments })] }) })), _jsx(DialogActionsAtom, Object.assign({ sx: { display: "flex", justifyContent: "center", pb: 4 } }, { children: _jsxs(BoxStyled, { children: [_jsx(ButtonAtom, Object.assign({ variant: "outlined", color: "primary", sx: { width: "177px", mr: 2, padding: "11px 9px", fontWeight: "600", borderRadius: "0px", border: "1px solid #215EF7" }, onClick: handleCancelClick }, { children: "Cancel" })), _jsx(ButtonAtom, Object.assign({ variant: "contained", color: "primary", onClick: handleApproveClick, sx: { width: "177px", mr: 1, padding: "11px 9px", fontWeight: "bold", borderRadius: "0px", border: "1px solid #215EF7" } }, { children: "Approve" }))] }) }))] })));
};
export default ApprovalPopUp;
