import { jsx as _jsx } from "react/jsx-runtime";
import { createGlobalStyle } from '@epam/reactjs-common/styles';
import { useArsTheme } from '../../utils/theme.utils';
import globalStyles from './index.styles';
const GlobalStylesStyled = createGlobalStyle `
  ${globalStyles}
`;
const ArsGlobalStyles = () => {
    const theme = useArsTheme();
    return _jsx(GlobalStylesStyled, { theme: theme });
};
export default ArsGlobalStyles;
