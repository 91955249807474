import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@epam/reactjs-common/styles';
import { DividerAtom } from '@epam/reactjs-common';
import dividerStyles from './index.styles';
const DividerStyled = styled((props) => {
    const { removeMargin = false } = props;
    let { className } = props;
    if (removeMargin) {
        className = `${className} remove-margin`;
    }
    return _jsx(DividerAtom, Object.assign({}, props, { className: className }));
}) `
  ${dividerStyles}
`;
export default DividerStyled;
