import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@epam/reactjs-common/styles';
import DialogAtom from '@epam/reactjs-common/atoms/layout/Dialog';
import dialogStyles from './index.styles';
const DialogStyled = styled((props) => {
    return _jsx(DialogAtom, Object.assign({}, props));
}) `
  ${dialogStyles}
`;
export default DialogStyled;
