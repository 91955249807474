var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import BoxAtom from '@epam/reactjs-common/atoms/layout/Box';
import { styled } from '@epam/reactjs-common/styles';
import boxStyles from './index.styles';
const BoxStyled = styled((props) => {
    const { apType } = props, boxProps = __rest(props, ["apType"]);
    let { className } = props;
    if (apType === 'scroll') {
        className = `${className} scrollable`;
    }
    if (apType === 'countryForm') {
        className = `${className} country-form`;
    }
    return _jsx(BoxAtom, Object.assign({}, boxProps, { className: className }));
}) `
  ${boxStyles}
`;
export default BoxStyled;
